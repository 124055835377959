<template>
    <el-dialog title="详情" :visible.sync="dialogVisible" width="800" top="3vh" :before-close="handleClose">
        <el-form :model="editForm" ref="editForm" label-width="120px" class="demo-editForm">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="微信昵称:" prop="nickName" label-width="120px">
                        <div>
                            {{ editForm.nickName }}
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="姓名:" prop="fullName" label-width="120px">
                        <div>{{ editForm.fullName }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="手机号:" prop="phone" label-width="120px">
                        <div>{{ editForm.phone }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="身份证号:" prop="idNubmer" label-width="120px">
                        <div>{{ editForm.idNubmer }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="余额:" prop="balance" label-width="120px">
                        <div>{{ editForm.balance }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="积分:" prop="integrate" label-width="120px">
                        <div>{{ editForm.integrate }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="用户评分:" prop="score" label-width="120px">
                        <div>{{ editForm.score }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="用户状态:" prop="status" label-width="120px">
                        <div>{{ editForm.status == 1 ? '有效' : '无效' }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="认证方式:" prop="method" label-width="120px">
                        <div>{{ editForm.method == 1 ? '企业认证' : editForm.method == 2 ? '个人认证' : '' }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="是否认证:" prop="isAuthentication" label-width="120px">
                        <div>{{ editForm.isAuthentication == 0 ? '未认证' : editForm.isAuthentication == 1 ? '已认证' :
                            editForm.isAuthentication == 2 ? '认证中' : '' }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="认证身份:" prop="identity" label-width="120px">
                        <div>{{ editForm.identity == 1 ? '管理员' : editForm.identity == 2 ? '货主' : editForm.identity == 3 ?
                            '司机' : '' }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="认证时间:" prop="rzTime" label-width="120px">
                        <div>{{ editForm.rzTime }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="editForm.identity == 3">
                    <el-form-item label="车牌号:" prop="licensPlateNumber" label-width="120px">
                        <div>{{ editForm.licensPlateNumber }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="editForm.identity == 3">
                    <el-form-item label="挂车车牌号:" prop="hangLicensePlate" label-width="120px">
                        <div>{{ editForm.hangLicensePlate }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="editForm.identity == 3">
                    <el-form-item label="车牌颜色:" prop="colorValue" label-width="120px">
                        <div>{{ editForm.colorValue }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="editForm.identity == 3">
                    <el-form-item label="是否新能源:" prop="newEnergyCart" label-width="120px">
                        <div>{{ editForm.newEnergyCart == 0 ? '否' : editForm.newEnergyCart == 1 ? '是' : '' }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="editForm.identity == 3">
                    <el-form-item label="行驶证到期时间:" prop="drivingLicenseExpirationTime" label-width="120px">
                        <div>{{ editForm.drivingLicenseExpirationTime }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="editForm.identity == 3">
                    <el-form-item label="驾驶证到期时间:" prop="driverLicenseExpirationTime" label-width="120px">
                        <div>{{ editForm.driverLicenseExpirationTime }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if='editForm.negative'>
                    <el-form-item label="身份证正面:" prop="negative" label-width="120px">
                        <el-image class="images" :src="editForm.negative" fit="scale-down"
                            @click="imageUrl = editForm.negative, imgVisible = true"></el-image>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if='editForm.front'>
                    <el-form-item label="身份证反面:" prop="front" label-width="120px">
                        <el-image class="images" :src="editForm.front" fit="scale-down"
                            @click="imageUrl = editForm.front, imgVisible = true"></el-image>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="editForm.businessLicense">
                    <el-form-item label="营业执照:" prop="businessLicense" label-width="120px">
                        <el-image class="images" :src="editForm.businessLicense" fit="scale-down"
                            @click="imageUrl = editForm.businessLicense, imgVisible = true"></el-image>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="editForm.driverLicense">
                    <el-form-item label="驾驶证:" prop="driverLicense" label-width="120px">
                        <el-image class="images" :src="editForm.driverLicense" fit="scale-down"
                            @click="imageUrl = editForm.driverLicense, imgVisible = true"></el-image>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="editForm.drivingLicense">
                    <el-form-item label="行驶证:" prop="drivingLicense" label-width="120px">
                        <el-image class="images" :src="editForm.drivingLicense" fit="scale-down"
                            @click="imageUrl = editForm.drivingLicense, imgVisible = true"></el-image>
                    </el-form-item>
                </el-col>

                <el-col :span="12" v-if="editForm.roadTransportBusinessLicensePath">
                    <el-form-item label="道路运输经营许可证:" prop="driverLicense" label-width="120px">
                        <el-image class="images" :src="editForm.roadTransportBusinessLicensePath" fit="scale-down"
                            @click="imageUrl = editForm.roadTransportBusinessLicensePath, imgVisible = true"></el-image>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="editForm.roadTransportQualificationCertificatePath">
                    <el-form-item label="道路运输从业资格证:" prop="roadTransportQualificationCertificatePath" label-width="120px">
                        <el-image class="images" :src="editForm.roadTransportQualificationCertificatePath" fit="scale-down"
                            @click="imageUrl = editForm.roadTransportQualificationCertificatePath, imgVisible = true"></el-image>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="editForm.identity == 3">
                    <el-form-item label="公众号:" prop="isGzh" label-width="120px">
                        <div>{{ editForm.isGzh == 1 ? '已关注' : editForm.isGzh == 0 ? '未关注' : '' }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="创建时间:" prop="createTime" label-width="120px">
                        <div>{{ editForm.createTime }}</div>
                    </el-form-item>
                </el-col>

            </el-row>
        </el-form>
        <el-dialog title="预览" :visible.sync="imgVisible" :before-close="imgClose" append-to-body>
            <div style="text-align: center;" v-if="imgVisible">
                <el-image style="width: 400px; height: 400px;text-align: center;" :src="imageUrl"
                    fit="scale-down"></el-image>
            </div>

        </el-dialog>
    </el-dialog>
</template>

<script>
import VploadImgTwo from "../inc/VploadImgTwo";
export default {
    name: "AddCertifiion",
    components: {
        VploadImgTwo,
    },
    data() {
        return {
            editForm: {},
            dialogVisible: false,
            imgVisible: false,
            imageUrl: null,
        }
    },
    methods: {
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.dialogVisible = false
            this.editForm = {}
        },
        handleClose() {
            this.resetForm('editForm')
        },
        imgClose() {
            this.imgVisible = false
        },
        init(id) {
            this.dialogVisible = true;
            if (id != null && id != '' && id > 0) {
                this.$axios.post('/admin/userBase/getUserInfo', { userId: id }).then(res => {
                    this.editForm = res.data
                })
            }

        },
    }
}
</script>

<style scoped>
.images {
    width: 100px;
    height: 100px;
}
</style>
